import * as S from './styles.js'
import { ChatIcon } from '../../../icons'
import { intercomService } from '../../../services/Analytics/platforms/intercom'
import { analytics } from '../../../services/Analytics/Analytics'
import { ANALYTICS_EVENTS_NAMES } from 'Art/services/ArtAnalytics/consts.js'
import { useLocation } from 'react-router-dom'

export default function ChatSupportButton({
  onChatOpen = () => {},
  color,
  source = 'Top Bar',
}: {
  onChatOpen?: () => void
  color?: string
  source?: string
}) {
  const location = useLocation()

  function handleClick() {
    intercomService.show()
    analytics.track(ANALYTICS_EVENTS_NAMES.TALK_TO_US_TAPPED, {
      Source: source,
      URL: location.pathname,
    })
    onChatOpen()
  }

  return (
    <S.Clickable onClick={handleClick} $color={color}>
      <ChatIcon color={color} />
    </S.Clickable>
  )
}
