export const ANALYTICS_EVENTS_NAMES = {
  FIRST_OPEN: 'First Open',
  PRODUCT_TAPPED: 'Product Tapped',
  PRODUCT_VIEWED: 'Product Viewed',
  FRAME_TAPPED: 'Frame Tapped',
  FRAME_SELECTED: 'Frame Selected',
  SIZE_TAPPED: 'Size Tapped', // No
  SIZE_SELECTED: 'Size Selected',
  LAYOUT_TAPPED: 'Layout Tapped',
  LAYOUT_SELECTED: 'Layout Selected',
  REPLACE_ARTWORK_TAPPED: 'Replace Artwork Tapped',
  ARTWORK_REPLACED: 'Artwork Replaced',
  ARTWORK_TAPPED: 'Artwork Tapped',
  ARTWORK_UNTAPPED: 'Artwork Untapped',
  ARTWORKS_SELECTED: 'Artworks Selected',
  TALK_TO_US_TAPPED: 'Talk To Us Tapped',
  PITCH_GET_STARTED_TAPPED: 'Pitch - Get Started Tapped',
  PITCH_SCROLLED_TO_BOTTOM: 'Pitch - Scrolled To Bottom',
  ONBOARDING_LETS_GO_TAPPED: "Onboarding - Let's Go Tapped",
  ONBOARDING_SKIP_TAPPED: 'Onboarding - Skip Tapped',
  ONBOARDING_PREVIOUS_TAPPED: 'Onboarding - Previous Tapped',
  ONBOARDING_COMPLETED: 'Onboarding - Completed',
  ONBOARDING_QUESTION_ANSWERED: 'Onboarding - Question Answered',
  CART_TAPPED: 'Cart Tapped',
  CART_CHANGED: 'Art: Cart Changed',
  CART_PRODUCT_EDIT_TAPPED: 'Cart - Product Edit Tapped',
  FOOTER_ITEM_TAPPED: 'Footer Item Tapped',
  LOGO_TAPPED: 'Mixtiles Art Logo Tapped',
  SEARCH_TAPPED: 'Search Tapped',
  SEARCH_FILTER_SELECTED: 'Search - Filter Selected',
  CATEGORY_FILTER_SELECTED: 'Category - Filter Selected',
  SEARCH_SUBMIT: 'Search - Submit',
  PRODUCT_PAGE_CHOOSE_ARTWORKS_TAPPED: 'Product Page - Choose Artworks Tapped',
  PRODUCT_PAGE_CHOOSE_FRAME_CLICKED: 'Product Page - Choose Frame Clicked',
  PRODUCT_PAGE_CHOOSE_CUSTOMIZE_CLICKED: 'Product Page - Customize Clicked',
  PRODUCT_PAGE_BROWSE_ALL_ARTWORKS_TAPPED:
    'Product Page - Browse All Artworks Tapped',
  DISCOVERY_VIEW_CATEGORY_TAPPED: 'Discovery - View Category Tapped',
  DISCOVERY_CATEGORY_VIEW_MORE_TAPPED:
    'Discovery - Categories View More Tapped',
  DISCOVERY_BROWSE_ALL_TAPPED: 'Discovery - Browse All Tapped',
  DISCOVERY_GALLERY_WALL_TAPPED: 'Discovery - Gallery Wall Category Tapped',
  DISCOVERY_ARTIST_TAPPED: 'Discovery - Artist Tapped',
  DISCOVERY_TOP_SEARCH_TAPPED: 'Discovery - Top Search Tapped',
  DISCOVERY_SEARCH_VIEW_MORE_TAPPED:
    'Discovery - Top Searches View More Tapped',
  PRODUCT_EDITED: 'Product Edited',
  MENU_OPENED: 'Menu Opened',
  MENU_BROWSE_ART_TAPPED: 'Menu - Browse Art Tapped',
  MENU_TAKE_THE_QUIZ_TAPPED: 'Menu - Take The Quiz Tapped',
  MENU_FRAME_YOUR_PHOTOS_TAPPED: 'Menu - Frame Your Photos Tapped',
  REDIRECTED_FROM_CLASSIC_TO_ART: 'Redirected From Classic To Art',
  CHECKOUT_TAPPED: 'Checkout Tapped',
  ITEM_ADDED_TO_CART: 'Item Added To Cart',
  ITEM_REMOVED_FROM_CART: 'Item Removed From Cart',
  ARTIST_NAME_CLICKED: 'Artist Name Clicked',
  DISCOVERY_SECTIONS_LOADED: 'Discovery Sections Loaded',
  ART_APP_LOADING: 'Start Loading Art App',
  ART_PRODUCT_LOADING: 'Start Loading Art Product',
  ARTIST_PAGE_VIEWED: 'Artist Page Viewed',
  CATEGORY_PAGE_VIEWED: 'Category Page Viewed',
  RECENTLY_VIEWED_PRINTS: 'Recently Viewed Prints',
  WISHLIST_TAPPED: 'Wishlist Tapped',
  ITEM_ADDED_TO_WISHLIST: 'Item Added To Wishlist',
  ITEM_REMOVED_FROM_WISHLIST: 'Item Removed From Wishlist',
  WISHLIST_CUSTOMIZE_ITEM_TAPPED: 'Wishlist - Customize Item Tapped',
  WISHLIST_CHANGED: 'Wishlist Changed',
  MENU_ITEM_TAPPED: 'Menu Item Tapped',
  MENU_ROOM_TAPPED: 'Menu - Room Tapped',
  MENU_CATEGORY_TAPPED: 'Menu - Category Tapped',
  PREVIEW_PAGE_ADD_TO_CART_TAPPED: 'Preview Page - Add To Cart Tapped',
  PREVIEW_PAGE_CUSTOMIZE_TAPPED: 'Preview Page - Customize Tapped',
  AR_QUICKLOOK_TAPPED: 'AR Quick Look Tapped',
  PRODUCT_PAGE_VISIT: 'Product Page: Visit',
  PRODUCT_PAGE_FRAME_CHANGED: 'Product Page: Frame Changed',
  PRODUCT_PAGE_SIZE_CHANGED: 'Product Page: Size Changed',
  PRODUCT_PAGE_LOAD_MORE_REVIEWS_TAPPED:
    'Product Page: Load More Reviews Tapped',
  PRODUCT_PAGE_FAQ_TAPPED: 'Product Page: FAQ tapped',
  PRODUCT_PAGE_ADD_ITEM_TAPPED: 'Product Page: Add Item Tapped',
  PRODUCT_PAGE_BROWSE_ART_TAPPED: 'Product Page: Browse Art Tapped',
  PRODUCT_PAGE_VIDEO_SWIPE_TAPPED: 'Product Page: Video Swipe Tapped',
  PRODUCT_PAGE_PHOTO_SHOT_SWIPED: 'Product Page: Product Shot Swiped',
}

export const ART_ANALYTICS_EVENTS_NAMES = {
  STYLE_MATCH_STAY_TUNED_BROWSE_TAPPED:
    'Style Match - Stay Tuned Browse Tapped',
  STYLE_MATCH_VALID_PHONE_ENTERED: 'Style Match - Valid Phone Entered',
  STYLE_MATCH_PHONE_NEXT_TAPPED: 'Style Match - Phone Next Tapped',
  STYLE_MATCH_INTRO_SKIP_TAPPED: 'Style Match - Intro Skip Tapped',
  STYLE_MATCH_INTRO_UPLOAD_TAPPED: 'Style Match - Intro Upload Tapped',
  STYLE_MATCH_INTRO_UPLOAD_COMPLETED: 'Style Match - Intro Upload Completed',
}
